<script lang="ts" context="module">
    import type { Load } from '@sveltejs/kit';

    export const load: Load = ({ error, status, params }) => {
        return {
            props: {
                status,
                error,
                slug: params.slug,
            },
        };
    };
</script>

<script lang="ts">
    import Search from '$lib/components/nav/Search.svelte';

    export let status: number;
    export let error: Error;
    export let slug: string;
</script>

<section class="my-5 grid min-h-screen">
    {#if status === 404}
        <div class="">
            <p>404</p>
            <h1>Could not find post with slug: "{slug}"</h1>

            <div class="mt-10 max-w-[50ch]">
                <Search />
            </div>
        </div>
    {:else}
        <div>
            <code>
                {error.name}
            </code>

            <p>
                {error.message}
            </p>
            <code>
                {error.stack}
            </code>
        </div>
    {/if}
</section>
